export function getOsPlatform() {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  const linuxPlatforms = [
    'Linux x86_64',
    'Linux x86_64 X11',
    'Linux ppc64',
    'Linux i686 X11',
    'Linux i686 on x86_64',
    'Linux i686',
  ]

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'macos'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'ios'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'windows'
  } else if (/Android/.test(userAgent)) {
    return 'android'
  } else if (linuxPlatforms.indexOf(platform) !== -1) {
    return 'linux'
  } else {
    return 'unknown'
  }
}

export function getBrowserPlatform() {
  if (isBrowserBrave()) {
    return 'brave'
  } else if (isBrowserOpera()) {
    return 'opera'
  } else if (isBrowserChrome()) {
    return 'chrome'
  } else if (isBrowserSafari()) {
    return 'safari'
  } else if (isBrowserFirefox()) {
    return 'firefox'
  } else if (isBrowserEdge()) {
    return 'edge'
  } else if (isBrowserIE()) {
    return 'ie'
  } else {
    return 'unknown'
  }
}

export function isBrowserChrome() {
  return window.navigator.userAgent.indexOf('Chrome') !== -1
}

export function isBrowserFirefox() {
  return window.navigator.userAgent.indexOf('Firefox') !== -1
}

export function isBrowserSafari() {
  // return window.navigator.userAgent.indexOf("Safari") !== -1;
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iP(ad|od|hone)/i)
  const hasSafariInUa = !!ua.match(/Safari/i)
  const noOtherBrowsersInUa = !ua.match(
    /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i
  )
  let result = false
  if (iOS) {
    // detecting Safari in IOS mobile browsers
    var webkit = !!ua.match(/WebKit/i)
    result = webkit && hasSafariInUa && noOtherBrowsersInUa
  } else if (window.safari !== undefined) {
    // detecting Safari in Desktop Browsers
    result = true
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa
  }
  return result
}

export function isBrowserOpera() {
  return window.navigator.userAgent.indexOf('Opera') !== -1
}

export function isBrowserIE() {
  return window.navigator.userAgent.indexOf('MSIE') !== -1
}

export function isBrowserBrave() {
  return (
    isBrowserChrome() &&
    window.navigator.plugins.length === 0 &&
    window.navigator.mimeTypes.length === 0
  )
}

export function isBrowserEdge() {
  return window.navigator.userAgent.indexOf('Edge') !== -1
}

export function getPlatformData() {
  return {
    platform: getOsPlatform(),
    browser: getBrowserPlatform(),
  }
}
