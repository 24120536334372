import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={96}
      height={16}
      viewBox="0 0 96 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333zM28 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L28 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L28 1.333zM48 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L48 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L48 1.333zM68 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L68 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L68 1.333z"
        fill={props.fill || '#D2EB83'}
        stroke={props.fill || '#D2EB83'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L88 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L88 1.333z"
        fill="#E2E2E2"
        stroke="#E2E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88 1.333l2.06 4.173L90.5 7v6L88 11.846l-4.12 2.167.787-4.587-3.334-3.246 4.607-.674L88 1.333z"
        fill={props.fill || '#D2EB83'}
        stroke={props.fill || '#D2EB83'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
