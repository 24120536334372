import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import Box from '../../components/box'
import RowLayout from '../../components/rowLayout'
import TabPickerInverted from '../../components/tabPickerInverted'
import IntegrationCard from '../../components/integrationCard'
import CompanyIcons from '../../components/companyIcons'
import Hidden from '../../components/hidden'
import Accordion from '../../components/accordion'
import Typography from '../../components/typography'
import HeroVideo from '../../components/heroVideo'
import { Base, Advanced } from '../../containers/pricing'
import AccordionSingleSelect from '../../containers/accordionSingleSelect'
import {
  H1,
  H3,
  CopyBody,
  CopyBodyH2,
  CopySubText,
  Ul,
  Li,
  Button,
  Pink,
  Uppercase,
  Green,
  TryPulse,
} from '../index'

import extensionLinks from '../../utils/constants/extensionLinks'
import checkUtmParams from '../../utils/checkUtmParams'
import { getBrowserPlatform } from '../../utils/checkPlatform'
import useHasMounted from '../../utils/hooks/useHasMounted'

import HomePatternSvg from '../../assets/svg/home/home-pattern-one.svg'
import BlackCheckSvg from '../../assets/svg/home/black-check.svg'

import StarsSvg from '../../icons/startPageStars'
import PersonalizedSvg from '../../assets/svg/home/personalized.svg'
import TeamSvg from '../../assets/svg/home/team.svg'
import BrowserSvg from '../../assets/svg/home/browser.svg'
import useHotjarScript from '../../utils/hooks/useHotjarScript'

const OpsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            fileAbsolutePath: { regex: "/(content/integrations/).*\\\\.md$/" }
          }
        ) {
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                label
                description
                categorySlugs
                status
                website
                plan
                faqs {
                  q
                  a
                }
                icon {
                  extension
                  publicURL
                }
              }
            }
          }
        }
        informedAligned: file(
          relativePath: { eq: "use-cases/ops-informed-aligned.png" }
        ) {
          childImageSharp {
            fixed(width: 540, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        informedAlignedMobile: file(
          relativePath: { eq: "use-cases/ops-informed-aligned-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hqVital: file(relativePath: { eq: "use-cases/hq-vital.png" }) {
          childImageSharp {
            fixed(width: 560, height: 674, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hqVitalMobile: file(
          relativePath: { eq: "use-cases/hq-vital-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 560, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exploreConnectBound: file(
          relativePath: { eq: "explore-connect-bound.png" }
        ) {
          childImageSharp {
            fixed(width: 570, height: 373, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exploreConnectBoundMobile: file(
          relativePath: { eq: "explore-connect-bound-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 570, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        startPage: file(relativePath: { eq: "start-page-full.png" }) {
          childImageSharp {
            fixed(width: 883, height: 541, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        startPageMobile: file(relativePath: { eq: "start-page-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 602, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        keepStaffAligned: file(
          relativePath: { eq: "use-cases/keep-staff-aligned-ops.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        keepPeopleEngaged: file(
          relativePath: { eq: "use-cases/keep-people-engaged.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        streamlinePointyComms: file(
          relativePath: { eq: "use-cases/streamline-pointy-comms.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        keepStakeholdersAligned: file(
          relativePath: { eq: "use-cases/keep-stakeholders-aligned-ops.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const integrations = data.allMarkdownRemark.edges

  const hasMounted = useHasMounted()
  const browser = hasMounted && getBrowserPlatform()
  const toExtensionDownload = () => {
    const extLink =
      browser === 'firefox' ? extensionLinks.FIREFOX : extensionLinks.CHROME
    window.open(
      extLink,
      '_blank' //
    )
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <Container>
      <SEO
        title="Pulse for HR and Operations Teams - Use cases"
        description="Leverage the power of asynchronous communication to easily connect people to information at the right time, and streamline processes more effectively."
      />
      <Nav />
      <HomePatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      />
      <Banner>
        <RowLayout>
          <HeroHeader>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '4.375rem',
                zIndex: 2,
                position: 'relative',
              }}
            >
              <div style={{ width: '100%' }}>
                <HeroFor>For Ops & HR Teams</HeroFor>
                <H1 style={{ width: '42.1875rem', maxWidth: '100%' }}>
                  Streamline comms <br />
                  with ease at scale
                </H1>
                <Button onClick={() => navigate('/signup')}>
                  {' '}
                  Get Pulse For Free{' '}
                </Button>
                <p id="hero-subtext">Create your workspace in {'<'}2min</p>
              </div>
              <Hidden widthDown={1160}>
                <HeroVideo
                  thumbnailSrc={'hero-thumbnail/hero-thumbnail-ops.png'}
                />
              </Hidden>
            </Box>
            <CopyBodyH2
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                maxWidth: '100%',
              }}
            >
              Leverage the power of asynchronous communication to easily{' '}
              <Pink> connect people to information</Pink> in the right place, at
              the right time, and <Green>streamline processes</Green> with +
              flexibility and - extra effort.
            </CopyBodyH2>
          </HeroHeader>
        </RowLayout>
        <RowLayout>
          <Hidden widthDown={1160}>
            <CompanyIcons />
          </Hidden>
        </RowLayout>
      </Banner>

      <RowLayout id="keep-people-informed">
        <Box style={{ flex: 1 }}>
          <H3> Keep your people informed and aligned.</H3>
          <CopySubText>
            Pulse helps you broadcast long-form updates to teams, departments,
            or the entire organization in a way that cuts through the noise,
            seamlessly gets in their workflow and makes it easy to promote and
            track alignment.
          </CopySubText>
          <SectionList>
            <Box className="copy-list">
              <Box className="copy-section">
                <h3 className="copy-header" style={{ color: '#EC8483' }}>
                  &gt; Amplify your message
                </h3>
                <p className="copy-body">
                  Share updates with one click across multiple mediums (Email,
                  Slack, etc) to meet your people where they are.
                </p>
              </Box>
              <Box className="copy-section">
                <h3 className="copy-header" style={{ color: '#EC8483' }}>
                  &gt; Track reader engagement
                </h3>
                <p className="copy-body">
                  Top-line open and click rates show readership over time.
                  Individual metrics help understand who is highly engaged and
                  who could benefit from a check-in.
                </p>
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Box className="copy-section">
                <h3 className="copy-header" style={{ color: '#EC8483' }}>
                  &gt; Request acknowledge
                </h3>
                <p className="copy-body">
                  Mission-critical update? Send requests to specific people or
                  teams, and ask for explicit read receipts.
                </p>
              </Box>
              <Box className="copy-section">
                <h3 className="copy-header" style={{ color: '#EC8483' }}>
                  &gt; Friendly email nudges
                </h3>
                <p className="copy-body">
                  Did someone miss the news? Pulse sends friendly email nudges
                  to those who missed the update in the shuffle so that you can
                  focus less on reminding things.
                </p>
              </Box>
            </Box>
          </SectionList>
        </Box>
        <Box style={{ flex: 1 }}>
          <Hidden widthDown={600}>
            <Img
              fixed={data.informedAligned.childImageSharp.fixed}
              fadeIn={false}
            />
          </Hidden>
          <Hidden widthUp={559}>
            <Box style={{ marginTop: '1rem' }} />
            <Img
              fluid={data.informedAlignedMobile.childImageSharp.fluid}
              fadeIn={false}
            />
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="your-hq-vital">
        <Box>
          <Box style={{ paddingTop: '5rem', flex: 1 }}>
            <H3> Your HQ for vital company news and staff updates. </H3>
            <CopySubText>
              From executive briefings to department updates – organize all
              staff comm in different streams. Like internal newsletters for
              your own people.
            </CopySubText>
            <SectionList>
              <Box className="copy-list">
                <Box className="copy-section">
                  <h3 className="copy-header" style={{ color: '#7BD7B3' }}>
                    &gt; Invite to follow
                  </h3>
                  <p className="copy-body">
                    Employees have never been more bombarded with information.
                    Invite people to follow streams and give them context for
                    why it matters.
                  </p>
                </Box>
                <Box className="copy-section">
                  <h3 className="copy-header" style={{ color: '#7BD7B3' }}>
                    &gt; Private or Public
                  </h3>
                  <p className="copy-body">
                    Need to share private updates with teams or people? Create
                    exclusive invite-only streams.
                  </p>
                </Box>
              </Box>
              <Box style={{ flex: 1 }}>
                <Box className="copy-section">
                  <h3 className="copy-header" style={{ color: '#7BD7B3' }}>
                    &gt; Manage contributors
                  </h3>
                  <p className="copy-body">
                    Add multiple contributors to streams to let others publish
                    updates and keep writing consistent, clear communications as
                    you scale.
                  </p>
                </Box>
                <Box className="copy-section">
                  <h3 className="copy-header" style={{ color: '#7BD7B3' }}>
                    &gt; Streamline your workflow
                  </h3>
                  <p className="copy-body">
                    Cut time with templates, collaborate on drafts, co-author
                    staff memos, and much more.
                  </p>
                </Box>
              </Box>
            </SectionList>
          </Box>
        </Box>
        <Box style={{ flex: 1, width: '100%' }}>
          <Hidden widthDown={600}>
            <Img fixed={data.hqVital.childImageSharp.fixed} fadeIn={false} />
          </Hidden>
          <Hidden widthUp={599}>
            <Box style={{ marginTop: '1rem' }} />
            <Img
              fluid={data.hqVitalMobile.childImageSharp.fluid}
              fadeIn={false}
            />
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="show-key-updates">
        <Box style={{ flex: 1 }}>
          <Box id="show-key-updates-container">
            <H3> Show key updates on everyone’s new tab. </H3>
            <CopySubText>
              Transform your browser new tab into a highly effective way to keep
              up with what’s important.
            </CopySubText>
            <Li>
              <Ul>
                <PersonalizedSvg />
                Personalized updates
              </Ul>
              <Ul>
                <TeamSvg />
                Team-based resources
              </Ul>
              <Ul>
                <BrowserSvg />
                Cross-browser support
              </Ul>
            </Li>
            <Hidden widthUp={599}>
              <Img
                fluid={data.startPageMobile.childImageSharp.fluid}
                fadeIn={false}
              />
            </Hidden>
            <Button id="explore-start-page-btn" onClick={toExtensionDownload}>
              {' '}
              Explore Start Page{' '}
            </Button>
          </Box>

          <Box id="ext-stars">
            <StarsSvg />
            <Hidden
              widthDown={599}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span className="rating">· 5 star rating</span>
            </Hidden>
          </Box>
        </Box>
        <Box style={{ flex: 1, width: '100%' }}>
          <Hidden widthDown={600}>
            <Img
              fixed={data.startPage.childImageSharp.fixed}
              fadeIn={false}
              style={{
                marginRight: '-300px',
              }}
            />
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="tab-picker-inverted">
        <Box>
          <Hidden widthDown={1024}>
            <TabPickerInverted
              options={[
                {
                  icon: <Uppercase>Staff Updates</Uppercase>,
                  title: 'Keep your staff informed on vital company news',
                  description: 'Send clear, more effective internal comms.',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.keepStaffAligned.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>Project Level News</Uppercase>,
                  title:
                    'Keep stakeholders aligned with progress of on-going initiatives',
                  description:
                    'Share decisions in streams. Like message boards that anyone can follow and read.',
                  component: (
                    <Box width="46.475rem" height="auto">
                      <Img
                        fluid={
                          data.keepStakeholdersAligned.childImageSharp.fluid
                        }
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>Department Updates</Uppercase>,
                  title:
                    'Streamline pointy comms to specific teams or departments',
                  description:
                    'Connect people to information with more flexibility and without overhead.',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.streamlinePointyComms.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>Internal Newsletters</Uppercase>,
                  title:
                    'Keep people engaged with 10x better internal newsletters',
                  description:
                    'Invite people to sign up then focus on writing content. We’ll handle the rest. ',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.keepPeopleEngaged.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Hidden>
          <Hidden widthUp={1023}>
            <Box>
              <AccordionSingleSelect>
                {({ handleAccordionChange, expandedId }) => (
                  <>
                    <Accordion
                      title="Staff Updates"
                      header="Keep your staff informed on vital company news"
                      handleChange={() => handleAccordionChange('1')}
                      active={expandedId === '1'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Send clear, more effective internal comms.
                          </Typography>
                          <Img
                            fluid={data.keepStaffAligned.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="Project Level News"
                      header="Keep stakeholders aligned with progress of on-going initiatives"
                      handleChange={() => handleAccordionChange('2')}
                      active={expandedId === '2'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Share decisions in streams. Like message boards that
                            anyone can follow and read.
                          </Typography>
                          <Img
                            fluid={
                              data.keepStakeholdersAligned.childImageSharp.fluid
                            }
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="Department Updates"
                      header={
                        'Streamline pointy comms to specific teams or departments'
                      }
                      handleChange={() => handleAccordionChange('3')}
                      active={expandedId === '3'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Connect people to information with more flexibility
                            and without overhead.
                          </Typography>
                          <Img
                            fluid={
                              data.streamlinePointyComms.childImageSharp.fluid
                            }
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="Internal newsletters"
                      header={
                        'Keep people engaged with 10x better internal newsletters'
                      }
                      handleChange={() => handleAccordionChange('4')}
                      active={expandedId === '4'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Invite people to sign up then focus on writing
                            content. We’ll handle the rest.
                          </Typography>
                          <Img
                            fluid={data.keepPeopleEngaged.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                  </>
                )}
              </AccordionSingleSelect>
            </Box>
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="zero-heavy-lifting">
        <H3 style={{ color: 'black' }}>
          Requires zero heavy lifting. <br />
          Deploy with your management today.
        </H3>
        <CopySubText style={{ color: '#757575' }}>
          Seamlessly pull, push, and auto-sync data from the tools you already
          use to cut to zero your IT effort.
        </CopySubText>
        <Box style={{ display: 'flex', overflowX: 'auto', marginTop: '3rem' }}>
          {integrations.map(integration => (
            <div key={integration.node.fields.slug}>
              <IntegrationCard
                integration={integration.node}
                key={integration.node.fields.slug}
                style={{
                  width: '15.125rem',
                  height: '13.25rem',
                  marginRight: '1.75rem ',
                }}
              />
            </div>
          ))}
        </Box>
      </RowLayout>
      <Hidden widthDown={600}>
        <RowLayout>
          <TryPulse>
            <Box style={{ width: '22.625rem' }}>
              <H3> Try Pulse in a snap of fingers</H3>
              <CopySubText style={{ color: '#FFFFFF' }}>
                The best way to learn what Pulse can do for you is to start with
                our Free plan and see it yourself.
              </CopySubText>
              <Li>
                <Ul>
                  <BlackCheckSvg />
                  Free up to 50 users
                </Ul>
                <Ul>
                  <BlackCheckSvg />
                  Unlimited updates (up to 5GB)
                </Ul>
                <Ul>
                  <BlackCheckSvg />
                  Unlimited history review
                </Ul>
              </Li>
              <Button
                style={{
                  marginTop: '2rem',
                  marginBottom: '1rem',
                }}
                onClick={() => navigate('/signup')}
              >
                Get Pulse For Free
              </Button>
              <Link to={'/pricing'} id="see-our-plans-link">
                Want more? See our plans
              </Link>
            </Box>
            <Box id="pricing-levels">
              <Base callToActionText="Learn more" />
              <Advanced callToActionText="Learn more" />
            </Box>
          </TryPulse>
        </RowLayout>
      </Hidden>

      <Footer />
    </Container>
  )
}

const Container = styled.div`
  #see-our-plans-link {
    display: block;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
  }
  #keep-people-informed {
    padding-top: 0rem;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-top: 3.75rem;
      flex-direction: row;
    }
  }
  #zero-heavy-lifting {
    flex-direction: column;
    background-color: #f6f6f6;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media (min-width: 600px) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
  #your-hq-vital {
    padding-top: 0rem;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-top: 3.75rem;
      flex-direction: row;
    }
  }
  #show-key-updates {
    padding-top: 3.75rem;
    padding-bottom: 3rem;
    padding-right: 0;
    align-items: center;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-bottom: 7.5rem;
      flex-direction: row;
    }
  }
  #show-key-updates-container {
    padding-right: 1rem;
    @media (min-width: 600px) {
      padding-right: 12rem;
    }
  }
  #explore-start-page-btn {
    margin-top: 2rem;
    width: 100%;
    background: #ffffff;
    color: #000;
    @media (min-width: 600px) {
      width: max-content;
    }
  }
  #ext-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.75rem;
    .rating {
      font-size: 0.875rem;
      font-family: ${props => props.theme.typography.fontFamilyGTMono};
      color: #fff;
      margin-left: 0.75rem;
      display: flex;
      align-items: center;
    }

    @media (min-width: 600px) {
      align-items: stretch;
      justify-content: flex-start;
    }
  }
  #tab-picker-inverted {
    background: none;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (min-width: 600px) {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
    }
  }
`

const Banner = styled.div`
  width: 100%;
  min-height: 40.75rem;
  max-height: max-content;
  background-color: black;
  background-size: cover;
  padding-bottom: 2rem;
  box-sizing: border-box;
  position: relative;
`

const HeroFor = styled.p`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 1rem;
  line-height: 1.75rem;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  margin-bottom: 1.5rem;
`

const HeroHeader = styled.div`
  max-width: 100%;

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`

const SectionList = styled.section`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;

  .copy-list {
    flex: 1;
    margin-right: 0rem;
  }

  .copy-section {
    margin-bottom: 2rem;
  }

  .copy-header {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  .copy-body {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.9);
  }

  @media (min-width: 600px) {
    flex-direction: row;
    .copy-list {
      margin-right: 2rem;
    }
    .copy-body {
      width: 18rem;
    }
  }
`

export default OpsPage
